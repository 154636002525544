.auth_popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .auth_popup_inner {
    background: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    max-width: 400px;
    width: 100%;
    text-align: center;
  }
  
  .auth_popup_inner h2 {
    margin-bottom: 20px;
  }
  
  .auth_popup_inner form {
    display: flex;
    flex-direction: column;
  }
  
  .auth_popup_inner input {
    margin-bottom: 10px;
    padding: 10px;
    font-size: 16px;
  }
  
  .auth_popup_inner button {
    padding: 10px;
    font-size: 16px;
    margin-top: 10px;
    color: #fff;
    background: #006fff;
    border: none;
    border-radius: 4px;
    font-weight: 600;
}
  
.auth_popup_close {
    margin-top: 10px;
    background: none;
    border: none;
    color: #717171;
    cursor: pointer;
    font-size: 13px;
    font-weight: 500;
}
  
  .auth_toggle p {
    margin-top: 10px;
  }
  
  .auth_toggle span {
    color: #006fff;
    cursor: pointer;
    text-decoration: underline;
  }
  
  .auth_toggle span:hover {
    text-decoration: none;
  }
  

  .auth_popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .auth_popup_inner {
    background: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    max-width: 400px;
    width: 100%;
    text-align: center;
  }
  
  .auth_popup_inner h2 {
    margin-bottom: 20px;
  }
  
  .auth_popup_inner form {
    display: flex;
    flex-direction: column;
  }
  
  .auth_popup_inner input {
    margin-bottom: 10px;
    padding: 10px;
    font-size: 16px;
  }
  
  .auth_popup_inner button {
    padding: 10px;
    font-size: 16px;
    margin-top: 10px;
  }
  
  .auth_popup_close {
    margin-top: 10px;
    background: none;
    border: none;
    color: blue;
    cursor: pointer;
  }
  
  .auth_toggle p {
    margin-top: 10px;
  }
  
  .auth_toggle span {
    color: blue;
    cursor: pointer;
    text-decoration: underline;
  }
  
  .auth_toggle span:hover {
    text-decoration: none;
  }
  
