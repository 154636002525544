.homepage_main_master_text1 {
    font-size: 30px;
}
.homepage_main_master_text1 {
    font-size: 35px;
    margin: 50px 50px;
}
.homepage_main_container {
    display: flex;
    text-align: center;
    flex-direction: column;
}
.homepage_main_master_text2 {
    color: #006fff;
    padding: 18px;
    text-align: start;
    font-size: 20px;
    font-weight: 600;
    margin-left: 12%;
}
.homepage_main_container_location_search {
    display: flex;
    justify-content: start;
    width: 56%;
    /* margin-left: 12.5%; */
    height: -webkit-fill-available;
    margin-top: 13px;
}
input.homepage_main_container_location_input {
    margin-left: 10px;
}
input.homepage_main_container_search_input {
    margin-left: 10px;
}
input.homepage_main_container_category_input {
    margin-left: 10px;
}
.homepage_main_container_location {
    margin-right: 20px;
    display: flex;
}
.homepage_main_container_location input{
    outline: none;
}
.homepage_main_container_category {
    margin-right: 20px;
    display: flex;
}
.homepage_main_container_search {
    margin-right: 20px;
    margin-left: 44px;
    display: flex;
}
input.homepage_main_container_location_input {
    margin-left: 10px;
    font-size: 15px;
    width: max-content;
    line-height: 30px;
    padding-left: 10px;
    outline: none;
}
input.homepage_main_container_category_input {
    margin-left: 10px;
    font-size: 15px;
    width: max-content;
    line-height: 30px;
    padding-left: 10px;
    outline: none;
}
input.homepage_main_container_search_input {
    margin-left: 10px;
    font-size: 12px;
    line-height: 35px;
    min-width: 400px;
    padding-left: 10px;
    outline: none;
    border: .5px solid;
    border-radius: 4px;
}
.homepage_main_container_project_listing {
    width: 75%;
    display: flex;
    margin: auto;
    flex-direction: row;
    flex-wrap: wrap;
    /* margin: 100px; */
    padding-top: 50px;
    /* z-index: -1; */
}

.project_card_navigations_2_contributions {
    padding: 10px;
    background: #006fff;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
}
.project_card_navigations_like {
    margin: auto;
}
.project_card_navigations_share {
    margin: auto;
}
.homepage-container-body {
    display: flex;
}
.homepage-sidenav {
    min-width: 15%;
    border-right: 1px solid lightgray;
}
.homepage-sidenav ul {
    padding:0px;
    margin: 0px;
}
.homepage-sidenav ul a li:hover{
    background-color: #f4f5f7;
    font-weight: 700;

}
img.sidenav_item_icon {
    width: 25px;
    margin-right: 5px;
}
.homepage-sidenav ul li {
    padding: 12px;
    display: flex;
    font-weight: 500;
}
span.sidenav_item_text {
    padding: 2px;
    text-decoration: none;
    color: #007bff;
}
.homepage-sidenav ul a:hover{
    background-color: lightgray;
}
.homepage-sidenav a {
    text-decoration: none;
}
.homepage_main_container_search_button {
    font-size: 14px;
    background-color: #006fff;
    padding: 14px;
    border-radius: 6px;
    color: #fff;
    padding: 10px 24px;
    font-weight: 700;
}
.homepage_no_results {
    margin-top: 200px;
    font-size: 28px;
    color: #006fff;
}
.documentation_button {
    padding: 10px;
    vertical-align: text-bottom;
    color: #fff;
    width: fit-content;
    text-align: center;
    align-self: center;
    color: #fff;
    padding: 10px;
    background-color: color(xyz-d50 0.26 0.42 0.49 / 0.84);
    font-weight: 900;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
}
img.documentation_button_icon {
    width: 20px;
}
.documentation_button_text {
    vertical-align: text-bottom;
    margin-left: 6px;
}