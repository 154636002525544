.dropdown {
    position: relative;
  }
  .dropdown.open > .dropdown-menu {
    display: block;
  }
  .dropdown .dropdown-toggle {
    display: inline-block;
    padding: 8px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    color: #000;
    background-color: #fff;
    /* border-color: #333; */
    border: 0.5px solid #333;
    border-radius: 4px;
    min-width: 100px;
}
  .dropdown .dropdown-toggle .caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid\9 ;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }
  .dropdown .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  }
  .dropdown .dropdown-menu li {
    cursor: pointer;
  }
  .dropdown .dropdown-menu li :hover {
    background: #ddd;
  }
  .dropdown .dropdown-menu li > a {
    display: block;
    padding: 5px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
    text-decoration: none;
  }