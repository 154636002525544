.recently_contributed_container {
  width: 45%;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 2px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
  padding: 15px;
  min-height: 320px;
}
  
  .recently_contributed_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  
  .recently_contributed_title {
    font-size: 16px;
    font-weight: bold;
  }
  
  .info {
    font-size: 14px;
    color: #007bff;
    cursor: pointer;
  }
  
  .menu {
    font-size: 20px;
    cursor: pointer;
  }
  
  .recently_contributed_list {
    padding: 10px;
  }
  
  .recently_contributed_item {
    display: flex;
    align-items: center;
    padding: 8px 0;
    border-bottom: 1px solid #ddd;
  }
  
  .recently_contributed_item:last-child {
    border-bottom: none;
  }
  
  .recently_contributed_icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
  
  .recently_contributed_icon.iam {
    background-color: #ff4e42;
  }
  
  .recently_contributed_icon.s3 {
    background-color: #2e73b8;
  }
  
  .recently_contributed_icon.ec2 {
    background-color: #f89a1a;
  }
  
  .recently_contributed_text {
    font-size: 14px;
  }
  
  .recently_contributed_footer {
    text-align: center;
    padding: 10px;
    border-top: 1px solid #ddd;
    font-size: 12px;
}
  
  .recently_contributed_footer a {
    color: #007bff;
    text-decoration: none;
  }
  
  .recently_contributed_footer a:hover {
    text-decoration: underline;
  }
  
  .recent_contributions {
    width: 40%;
}

.recently_contributed_item {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  font-size: 12px;
  font-weight: 600;
}

.recently_contributed_text {
  flex: 2;
}

.recently_contributed_status {
  flex: 1;
  text-align: right;
  color: #666;
}

.recently_contributed_status.active {
  color: green;
}
.recently_contributed_title {
  font-size: 16px;
  font-weight: bold;
  color: #007bff;
}