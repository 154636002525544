.application-container {
  background-color: #fff;
  border-radius: 4px;
  padding: 20px;
  padding-bottom: 0px;
}

.application-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
}

.header-item {
  flex: 1;
  text-align: left;
  font-weight: bold;
  color: #555;
  font-size: 12px;
}

.header-item:not(:last-child) {
  margin-right: 10px;
}

.application-content {
  font-size: 12px;
  font-weight: 600;
  border-bottom: 1px solid lightgray;
}

.application-content p {
  margin: 10px 0;
  color: #777;
  font-size: 12px;
}

.create-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 2px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 13px;
  transition: background-color 0.3s ease;
}

.create-button:hover {
  background-color: #0056b3;
}

.project_listing_header {
  display: flex;
  justify-content: space-between;
}

.project_listing_main_container {
  padding: 15px;
  width: 40%;
  border-radius: 1px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
}

.project_listing_search_container {
  display: flex;
  gap: 10px;
  justify-content: center;
  position: relative; /* Add this to contain the dropdown */
}

.project_listing_search_region-dropdown {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
  font-size: 12px;
}

.project_listing_search_dropdown-arrow {
  margin-left: 5px;
}

.project_listing_search_search-box {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
}

.project_listing_search_search-icon {
  margin-right: 5px;
  color: #888;
}

.project_listing_search_search-box input {
  border: none;
  outline: none;
  width: 200px;
  font-size: 12px;
  color: #333;
}

.project_listing_search_search-box input::placeholder {
  color: #888;
}

.project_listing_header_text {
  font-weight: 900;
  color: #007bff;
}

.project_listing_header_create_button {
  background: #006fff;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  font-weight: 900;
  font-size: 12px;
  padding: 10px;
}

.project_listing_main_container {
  padding: 30px;
  border: 1px solid lightgray;
  border-radius: 5px;
  border: 1px solid #ddd;
  width: 45%;
  min-height: 320px;
  max-height: 320px;
  padding-bottom: 50px;
}

.user_home_page {
  padding: 50px;
}

.project_listing_search {
  margin-top: 15px;
}

.application-row {
  display: flex;
  padding: 8px;
  justify-content: space-between;
  cursor: pointer;
  font-size: 10px;
  transition: background-color 0.3s ease;
}

.application-row:hover {
  background-color: #f1f1f1;
}

.row-item {
  flex: 1;
  text-align: left;
}

.row-item:last-child {
  color: green;
}

.project_listing_search_dropdown-menu {
  position: absolute;
  top: 87%;
  left: 19px;
  width: 39%;
  font-size: 12px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 5px;
  z-index: 10;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.project_listing_search_dropdown-item {
  padding: 8px 12px;
  cursor: pointer;
}

.project_listing_search_dropdown-item:hover {
  background-color: #f1f1f1;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 26px;
}

.pagination button {
  margin: 0 5px;
  padding: 5px 10px;
  background-color: #f1f1f1;
  border: 1px solid #ddd;
  cursor: pointer;
  border-radius: 2px;
  font-size: 10px;
  font-weight: 300;
}

.pagination button.active {
  background-color: #007bff;
  color: white;
}

.pagination button:disabled {
  background-color: #e0e0e0;
  cursor: not-allowed;
}
