.contribution_requests {
    width: 100%;
}
.contribution_requests_container {
    padding: 20px;
    border: 1px solid lightgray;
    border-radius: 5px;
    width: 90%;
    margin: auto;
    margin-top: 15px;
}
.contribution_requests_card_title span {
    font-weight: 700;
}
.contribution_requests_description {
    font-size: 10px;
    /* text-decoration: none; */
}
.contribution_requests_key_features_container {
    display: flex;
    font-size: 10px;
}
.contribution_requests_key_feature {
    padding: 10px;
    border-right: 1px solid lightgray;
}

.contribution_requests_key_feature_last {
    padding: 10px;
}
.contribution_requests_key_features_container {
    display: flex;
    font-size: 10px;
    border: 1px solid lightgray;
    border-radius: 3px;
    width: fit-content;
}
.contribution_requests_key_feature_key {
    font-weight: 700;
}
.contribution_requests_card {
    display: flex;
    justify-content: space-between;
}
.contribution_requests_key_feature_accept {
    padding: 20px;
    border-radius: 4px;
    padding-top: 9px;
    padding-bottom: 9px;
    font-weight: 600;
    background: #006fff;
    color: #fff;
    cursor: pointer;
}
.contribution_requests_key_feature_reject {
    padding: 20px;
    border-radius: 4px;
    padding-top: 9px;
    padding-bottom: 9px;
    font-weight: 600;
    margin-top: 15px;
    background: lightgray;
    cursor: pointer;
}
.contribution_requests_created_by {
    font-size: 12px;
    margin-top: 6px;
    font-weight: 500;
    display: flex;
}
.contribution_requests_created_at {
    font-size: 12px;
    margin-top: 8px;
    margin-bottom: 8px;
    font-weight: 500;
    display: flex;
}
.contribution_requests_created_by_text {
    font-weight: 200;
}
.contribution_requests_created_at_text {
    font-weight: 200;
}
.notification {
    position: fixed;
    top: 20px;
    right: 20px;
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    z-index: 1000;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    font-size: 16px;
  }
  .contribution_requests_status {
    padding: 5px 10px;
    border-radius: 3px;
    font-weight: bold;
  }
  
  .contribution_requests_status.approved {
    background-color: #4caf50;
    color: white;
  }
  
  .contribution_requests_status.rejected {
    background-color: #f44336;
    color: white;
  }
  .pagination-controls {
    display: flex;
    justify-content: center;
}