.create_project_container {
    width: 70%;
    padding: 20px;
    background-color: #fff;
}
  
  .create_project_header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .create_project_header h1 {
    font-size: 24px;
  }
  
  .info {
    font-size: 14px;
    color: #007bff;
    cursor: pointer;
  }
  
  .create_project_content {
    margin-bottom: 20px;
  }
  
  .section {
    margin-bottom: 20px;
  }
  
  .section h2 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .field {
    margin-bottom: 10px;
  }
  
  .field label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .field input,
  .field textarea,
  .field select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 5px;
  }
  
  .field textarea {
    height: 100px;
  }
  
  .learn_more {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 8px 12px;
    cursor: pointer;
  }
  
  .learn_more:hover {
    background-color: #0056b3;
  }
  
  .create_project_footer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  
  .create_project_footer button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 8px 12px;
    cursor: pointer;
  }
  
  .create_project_footer button:hover {
    background-color: #0056b3;
  }
  
  .create_project_container_sidenav_wrapper {
    display: flex;
    border: 1px solid lightgray;
    margin-top: 20px;
    width: 80%;
    margin: auto;
    margin-top: 20px;
}
.create_project_container_sidenav {
    width: 18%;
    padding: 20px;
    border-right: 1px solid lightgray;
}
.create_project_container_sidenav_stage_name {
    font-weight: 700;
    font-size: 12px;
}
.create_project_container_sidenav_stage {
    padding: 10px;
    /* background: #f5f1f1; */
    margin-top: 10px;
    border: 1px solid lightgray;
}
.create_project_container_sidenav_stage_detail {
    font-size: 10px;
}
.field p {
    font-size: 12px;
}

/* .loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 24px;
    z-index: 1000;
  } */
  
  .success-popup {
    position: fixed;
    top: 20px;
    right: 20px;
    background: #4caf50;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 1000;
  }