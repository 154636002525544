  /* On mouse-over, add a deeper shadow */
  .project_card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  
.project_card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    width: 30%;
    margin-right: 30px;
    margin-top: 30px;
    border-radius: 5px;
}
img.project_card_img {
    width: 100%;
    min-height: 300px;
    max-height: 300px;
}

.project_card_start_date {
    font-size: 10px;
    padding: 10px;
    padding-left: 20px;
    text-align: start;
    color: green;
    font-weight: 800;
}
.project_card_author {
    font-size: 10px;
    padding-left: 20px;
    text-align: start;
}
.project_card {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
    transition: 0.3s;
    width: 30%;
    margin-right: 30px;
    margin-top: 30px;
    border-radius: 5px;
}
.container {
    padding: 2px 16px;
    margin: 0px;
    font-size: 0.875rem;
    font-weight: 400;
    font-family: Inter, "sans-serif";
    line-height: 1.43;
    color: rgb(67, 82, 112);
    text-align: start;
    padding: 16px;
}
img.project_card_img {
    width: 100%;
    min-height: 200px;
    max-height: 200px;
}
img.project_card_navigations_like_heart {
    width: 20px;
}
.project_card_navigations_1 {
    display: flex;
}
.project_card_navigations {
    display: flex;
    justify-content: space-between;
    padding: 20px;
}
.project_card_navigations a{
    text-decoration: none;
}
.project_card_navigations_1 {
    display: flex;
    width: 20%;
    justify-content: space-around;
}
.project_card_navigations_2_contributions {
    color: #fff;
    text-decoration: none;
}
.project_card_title:hover{
    cursor: pointer;
    text-decoration: underline;
}
.project_card a {
    color: #000;
    text-decoration: none;
}
.countdown_timer_text {
    font-weight: 900;
}
.project_card_title {
    color: #000;
    font-size: 18px;
    font-weight: 900;
    min-height: 28px;
    padding: 10px;
    text-align: start;
    padding-left: 20px;
}