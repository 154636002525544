.footer {
    background-color: #1661c3; /* Dark navy for footer */
    color: white;
    padding: 2rem 1rem;
    text-align: center;
    box-sizing: border-box; /* Ensure padding doesn't cause overflow */
    margin-top: 300px;
}

.footer-links, .footer-social, .footer-contact, .newsletter-signup {
    margin: 1rem 0;
}

.footer-links a {
    color: white;
    margin: 0 10px;
    font-weight: bold;
    text-decoration: none;
}

.footer-links a:hover {
    color: lightgray; /* Primary color on hover */
}

.footer-social a {
    margin: 0 10px;
}

.footer-social ion-icon {
    font-size: 30px; /* Adjust size as needed */
    color: white; /* Color of the social media icons */
}

.newsletter-signup input {
    padding: 0.6rem;
    border-radius: 5px;
    border: none;
    margin-right: 10px;
    outline: none;
}

.newsletter-signup .cta-btn {
    background-color: #1890ff;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
}

.newsletter-signup .cta-btn:hover {
    background-color: #088395; /* Darker teal on hover */
}

.icon {
    font-size: 2rem; /* Adjust the size as needed */
    color: #071952; /* Dark navy color */
    margin-bottom: 1rem; /* Space between icon and text */
}
