.contribution_card {
    width: 90%;
    display: flex;
    justify-content: space-between;
    border: 1px solid lightgray;
    padding: 10px;
    border-radius: 4px;
    margin: auto;
    margin-top: 20px;
}
.contribution_event_detail_status {
    font-weight: 500;
    color: green;
}
.contribution_meta_info {
    width: 30%;
    display: flex;
    flex-direction: column;
}
.contribution_meta_info_name {
    font-weight: 700;
    color: #007bff;
}
.contribution_event_detail {
    padding: 25px;
}
.contribution_meta_info_description {
    font-size: 13px;
    margin-top: 10px;
}
.contributions_container {
    width: 100%;
}