.downloads-container {
    text-align: center;
    padding: 20px 80px;
    margin-top: 15px;
}
  .downloads-header {
    font-size: 3em;
    margin-bottom: 20px;
    color: #007bff;
    font-weight: 300;
}
  
.download-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    font-size: 18px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    margin: 28px 0;
    transition: background-color 0.3s ease;
}
  
  .download-button:hover {
    background-color: #007bff9d;
  }
  
  .platforms-grid {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .platform-tile {
    margin: 20px;
    text-align: center;
    cursor: pointer;
    border: 3px solid #007bff;
    border-radius: 10px;
    color: #007bff;
    padding: 20px;
    width: 100px;
    transition: transform 0.3s ease;
}
  
  .platform-tile:hover {
    transform: scale(1.1);
  }
  
  .platform-icon {
    font-size: 40px;
    width: 40px;
}
  
  .platform-name {
    margin-top: 10px;
    font-size: 16px;
  }
  
  .highlights-container {
    text-align: center;
    padding: 40px;
  }
  
  .highlights-title {
    font-size: 2.5rem;
    margin-bottom: 30px;
  }
  
  .highlights-grid {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  .highlight-card {
    /* background-color: #f9f9f9; */
    border-radius: 10px;
    padding: 10px;
    width: 300px;
    color: #007bff;
    text-align: center;
    border: 2px solid #007bff;
    transition: box-shadow 0.3s ease;
}
  
  .highlight-card:hover {
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  }
  
  .highlight-icon {
    font-size: 50px;
    margin-bottom: 20px;
    width: 60px;
    margin-top: 15px;
}
  
  .highlight-title {
    font-size: 1.5rem;
    color: #007bff;
    margin-bottom: 10px;
  }
  
  .highlight-description {
    font-size: .9rem;
    color: #007bff;
    margin-bottom: 20px;
  }
  
  .highlight-button {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
  }
  
  .highlight-button:hover {
    color: #007bff;
  }
  