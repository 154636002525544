.user_home_page {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 85%;
}
.user_home_page_first._section1 {
    display: flex;
    width: 80%;
    justify-content: space-between;
    margin: auto;
}
.user_home_page_first_section2{
    display: flex;
    width: 80%;
    justify-content: space-between;
    margin: auto;
    margin-top: 40px;
}