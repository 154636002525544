.project-detail-container {
  width: 80%;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  color: #333;
  padding-top: 30px;
}
  
  .project-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
  }
  
  .meta-logo {
    width: 50px;
    height: auto;
  }
  
  .project-title h1 {
    margin: 0;
    font-size: 2em;
    font-weight: bold;
    color: #007bff;
  }
  
  .project-subtitle {
    font-size: .7em;
    color: #666;
    padding: 5px 15px;
    color: #fff;
    background: #008000c2;
    border-radius: 4px;
}
  
  .project-actions {
    display: flex;
    align-items: center;
  }
  
  .project-actions .button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    margin: 0 5px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .project-actions .code-button {
    background-color: #333;
  }
  
  .project-access {
    padding: 20px 0;
    border-bottom: 1px solid #ccc;
  }
  
  .project-access p {
    margin: 0 0 10px 0;
  }
  
  .submit-button {
    background-color: #28a745;
    color: white;
    padding: 10px 15px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .model-details {
    padding: 20px 0;
  }
  
  .model-information {
    margin-bottom: 20px;
  }
  
  .model-information h3 {
    margin-top: 0;
  }
  
  .model-stats {
    margin-top: 20px;
  }
  
  .model-stats h3 {
    margin-bottom: 10px;
  }
  
  .model-stats ul {
    list-style-type: none;
    padding: 0;
  }
  
  .model-stats ul li {
    background-color: #f1f1f1;
    display: inline-block;
    margin-right: 10px;
    padding: 5px 10px;
    border-radius: 5px;
  }
  
  .project-top-header-buttons {
    display: flex;
    justify-content: space-between;
}
.project-author-org {
  display: flex;
}
.project-button-wrapper {
  display: flex;
}
.project-author-org-name {
  display: flex;
  margin: 0px 3px;
  padding: 6px;
  color: #007bff;
  font-weight: 700;
}
img.project-author-org-avatar {
  width: 31px;
  height: 31px;
}
.project-author-org-timestamp-wrapper {
  display: flex;
  padding: 6px;
  font-weight: 600;
  color: #191a1b;
}
.project-author-org-date-value {
  margin-left: 5px;
}
.project-upvotes {
  padding: 9px 12px;
  border: 1px solid #007bff;
  color: #007bff;
  border-radius: 20px;
  font-weight: 700;
  cursor: pointer;
}
.project-hashcode {
  padding: 6px 12px;
  border: 1px solid #007bff;
  border-radius: 20px;
  font-weight: 700;
  cursor: pointer;
}
.project-utils-popup {
  padding: 9px 12px;
  border: 1px solid #007bff;
  color: #007bff;
  border-radius: 20px;
  font-weight: 700;
  cursor: pointer;
}
.project-utils-contribute {
  padding: 9px 12px;
  border: 1px solid #007bff;
  border-radius: 20px;
  font-weight: 700;
  color: #fff;
  cursor: pointer;
  background: #007bff;
}
.project-button-wrapper {
  display: flex;
  justify-content: space-evenly;
  min-width: 30%;
}
.project-tabs {
    display: flex;
    justify-content: start;
    margin-top: 20px;
    border-bottom: 1px solid #ccc;
}

.project-tab-button {
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  color: rgb(95, 99, 104);
  /* background-color: #f1f1f1; */
  margin-right: 10px;
  transition: background-color 0.3s ease;
}

.project-tab-button.active {
  /* background-color: #008000c2; */
  color: #007bff;
  /* border-radius: 5px; */
  border-bottom: 2.5px solid #007bff;
  font-weight:600
}

.project-tab-button:hover {
  color: #007bff;
}

.project-tab-content {
  padding: 20px 0;
}
.project-tab-body {
  padding-top: 20px;
}

/* ProjectDetail.css */

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 600px;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's on top of other elements */
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 600px;
  position: relative;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.project-hashcode {
  padding: 9px 12px;
  border: 1px solid #007bff;
  border-radius: 20px;
  color: #007bff;
  font-weight: 700;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.project-hashcode:hover {
  background-color: #ddd;
}


/* Hashcode Modal */
.hashcode-url {
  font-family: monospace;
  background-color: #f5f5f5;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 10px 0;
  word-break: break-all;
}

.copy-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.copy-button:hover {
  background-color: #0056b3;
}

.project-upvotes-icon {
  width: 14px;
}
.project-utils-share {
  width: 14px;
}
img.project-utils-contribute-icon {
  width: 14px;
}
img.project-hashcode-import {
  width: 14px;
}