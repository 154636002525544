.header {
    min-width: 100%;
    min-height: 65px;
    background: #fff;
    display: flex;
    border-bottom: 3px solid color(xyz-d50 0.26 0.42 0.49 / 0.84);
    justify-content: space-between;
    position: sticky;
    top: 0px;
    z-index: 34553;
    padding-top: 10px;
}
.header_company_text a {
    /* margin-left: 50px; */
    font-size: 20px;
    font-weight: 700;
    color: #006fff;
    /* padding: 18px; */
    padding-top: 3px;
    text-decoration: none;
    text-align: center;
}
.header_downloads_button {
    padding: 8px 28px;
    border: 1px solid #006fff;
    border-radius: 5px;
    color: #006fff;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
}
.header_downloads a{
    color: #006fff;
    text-decoration: none;
    font-size: 15px;
    font-weight: 700;
}
.header_downloads_button:hover{
    background: #6693cd33;
}
img.header_avtar {
    width: 25px;
}
.header_username {
    font-size: 17px;
    font-weight: 600;
    color: #006fff;
}
.header_user_detail:hover {
    background-color: #f4f5f7;
}
.header_user_detail {
    display: flex;
    justify-content: space-between;
    margin: 8px;
    padding: 10px;
    border-radius: 4px;
    width: 9.5%;
    height: fit-content;
    cursor: pointer;
}
.dropdown_menu {
    position: absolute;
    top: 65px;
    right: 6px;
    background-color: white;
    color: black;
    border: 1px solid #ccc;
    border-radius: 2px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
  
.dropdown_item {
    padding: 10px 20px;
    cursor: pointer;
    color: #006fff;
    font-size: 13px;
    font-weight: 500;
}

.dropdown_item:hover {
    background-color: #f5f5f5;
}

.header_auth {
    display: flex;
    width: 25%;
    margin-top: 15px;
    height: -moz-fit-content;
    height: fit-content;
    justify-content: space-evenly;
}
.header_downloads {
    height: fit-content;
}

.header_auth_signup {
    color: #006fff;
    font-weight: 500;
    font-weight: 800;
    cursor: pointer;
    border: 1px solid;
    background-color: #28b44e;
    padding: 8px 28px;
    color: #fff;
    border-radius: 5px;
    height: -moz-fit-content;
    height: fit-content;
}
.header_auth_signin {
    color: #006fff;
    font-weight: 500;
    cursor: pointer;
    border: 1px solid;
    padding: 8px 28px;
    border-radius: 5px;
    height: fit-content;
}

.header_company_text {
    padding: 15px;
    padding-top: 0px;
    display: flex;
    flex-direction: column;
}
.text_brand {
    padding: 10px;
    font-size: 11px;
}
.homepage_main_container_location_search {
    display: flex;
    justify-content: start;
    width: 70%;
    /* margin-left: 12.5%; */
    height: -webkit-fill-available;
}
input.homepage_main_container_location_input {
    margin-left: 10px;
}
input.homepage_main_container_search_input {
    margin-left: 10px;
}
input.homepage_main_container_category_input {
    margin-left: 10px;
}
.homepage_main_container_location {
    margin-right: 20px;
    display: flex;
}
.homepage_main_container_location input{
    outline: none;
}
.homepage_main_container_category {
    margin-right: 20px;
    display: flex;
}
.homepage_main_container_search {
    margin-right: 20px;
    display: flex;
}
input.homepage_main_container_location_input {
    margin-left: 10px;
    font-size: 15px;
    width: max-content;
    line-height: 30px;
    padding-left: 10px;
    outline: none;
}
input.homepage_main_container_category_input {
    margin-left: 10px;
    font-size: 15px;
    width: max-content;
    line-height: 30px;
    padding-left: 10px;
    outline: none;
}
input.homepage_main_container_search_input {
    margin-left: 10px;
    font-size: 15px;
    line-height: 35px;
    min-width: 400px;
    padding-left: 10px;
    outline: none;
    border: .5px solid;
    border-radius: 4px;
}


.autocomplete-container {
    position: relative;
    width: 400px;
  }
  
  .autocomplete-dropdown {
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 102.5%;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
    margin-left: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
  
.autocomplete-item {
    padding: 10px;
    cursor: pointer;
    font-size: 12px;
}
  
  .autocomplete-item:hover {
    background-color: #f5f5f5;
  }
  
  .autocomplete-item:not(:last-child) {
    border-bottom: 1px solid #ddd;
  }
  