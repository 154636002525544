.recently_contributed_item {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    font-size: 12px;
    font-weight: 600;
  }
  
  .recently_contributed_text {
    flex: 2;
  }
  
  .recently_contributed_status {
    flex: 1;
    text-align: right;
    color: #666;
  }
  
  .recently_contributed_status.active {
    color: green;
  }
  